@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Regular"), local("Open Sans-Regular"), url("~typeface-open-sans/files/open-sans-latin-400.woff2") format("woff2"), url("~typeface-open-sans/files/open-sans-latin-400.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  src: local("Open Sans SemiBold"), local("Open Sans-SemiBold"), url("~typeface-open-sans/files/open-sans-latin-600.woff2") format("woff2"), url("~typeface-open-sans/files/open-sans-latin-600.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: normal;
  src: local("Source Code Pro Regular"), local("Source Code Pro-Regular"), url("~typeface-source-code-pro/files/source-code-pro-latin-400.woff2") format("woff2"), url("~typeface-source-code-pro/files/source-code-pro-latin-400.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: bold;
  src: local("Source Code Pro SemiBold"), local("Source Code Pro-SemiBold"), url("~typeface-source-code-pro/files/source-code-pro-latin-600.woff2") format("woff2"), url("~typeface-source-code-pro/files/source-code-pro-latin-600.woff") format("woff"); }
