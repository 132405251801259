html,
body {
  margin: 0;
  padding: 0; }

a {
  color: inherit; }

* {
  box-sizing: border-box; }
