main nav {
  background-color: #f6f6f6;
  display: flex;
  justify-content: space-evenly;
  margin: 0 -25vw;
  padding: 1rem 25vw; }
  main nav a {
    padding: 1rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 50vw; }
    main nav a [data-icon] {
      font-size: 2.5rem;
      opacity: 0.1;
      position: absolute;
      height: 100%;
      top: 0;
      transition: transform 0.25s ease-in-out; }
    main nav a.prev [data-icon] {
      left: 0; }
    main nav a.next [data-icon] {
      right: 0; }
    main nav a .label {
      opacity: 0.5;
      display: block;
      font-size: 0.9rem; }
    main nav a .title {
      display: block;
      margin-top: 0.65rem; }
    main nav a time {
      opacity: 0.75; }
    main nav a:hover {
      color: #0594cb; }
      main nav a:hover [data-icon] {
        opacity: 1; }
      main nav a:hover.prev [data-icon] {
        transform: translateX(-0.5rem); }
      main nav a:hover.next [data-icon] {
        transform: translateX(0.5rem); }
    main nav a + a {
      border-left: 1.5px solid #ddd; }

@media screen and (orientation: portrait) {
  main nav {
    margin: 0 -15vw;
    padding: 1rem 15vw; } }
