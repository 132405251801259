main article {
  padding: 2rem 0; }
  main article + article {
    border-top: 1.5px solid #eee; }
  main article header h2 {
    margin: 0; }
    main article header h2 a {
      color: #444;
      text-decoration: none; }
      main article header h2 a:hover {
        color: #777; }
  main article header .field {
    color: #aaa; }
    main article header .field + .field {
      margin-left: 0.65rem; }
  main article footer {
    border-top: 1.5px solid #eee;
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
    padding: 1rem 0; }
    main article footer strong {
      display: block; }
    main article footer .field {
      font-size: 0.8rem; }
      main article footer .field + .field {
        margin-left: 0.5rem; }
      main article footer .field a {
        color: unset; }
    main article footer .share {
      flex-grow: 1;
      text-align: right; }
      main article footer .share [data-icon] {
        font-size: 1.25rem; }
    main article footer [data-icon] {
      color: #ccc;
      margin-right: 0.125rem;
      transition: color 0.25s ease-in-out; }
      main article footer [data-icon]:hover.twitter {
        color: #1da1f2; }
      main article footer [data-icon]:hover.facebook {
        color: #3b5998; }
      main article footer [data-icon]:hover.google-plus {
        color: #dd4b39; }
  main article a,
  main article p.more a {
    color: #0594cb;
    transition: color 0.25s ease-in-out; }
    main article a:hover,
    main article p.more a:hover {
      color: #96ccff; }
  main article.preview a {
    color: unset; }
  main article p.more {
    margin-bottom: 0; }
    main article p.more a {
      align-items: baseline;
      display: inline-flex; }
    main article p.more [data-icon] {
      font-size: 0.6rem;
      margin-left: 0.25rem; }
  main article code,
  main article pre {
    background-color: #eee;
    border-radius: 0.15rem; }
  main article code {
    color: #444;
    font-family: "Source Code Pro", monospace;
    padding: 0 0.25rem; }
  main article pre {
    overflow: auto;
    padding: 0 0.25rem;
    text-overflow: ellipsis; }
  main article img {
    display: block;
    margin: 2rem auto;
    max-width: 50vw; }

main.content article {
  padding: 4rem 0; }
  main.content article header {
    margin-bottom: 2rem; }
    main.content article header h1 {
      font-size: 2.5rem;
      margin: 0; }
  main.content article section h2, main.content article section h3 {
    margin: 2rem 0 1rem; }
