body > footer {
  color: #aaa;
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
  padding: 1rem 25vw;
  text-align: center; }
  body > footer ul {
    display: flex;
    list-style-type: none;
    padding: 0; }
    body > footer ul li + li {
      margin-left: 1.5rem; }
  body > footer p.modified {
    opacity: 0.5; }
  body > footer a {
    text-decoration: none; }
    body > footer a [data-icon] {
      margin-right: 0.125rem; }

@media screen and (orientation: portrait) {
  body > footer {
    flex-direction: column;
    padding: 1rem 15vw; }
    body > footer ul {
      justify-content: center; } }
