body > header {
  align-items: center;
  display: flex;
  height: 6rem;
  padding: 0 25vw; }
  body > header .logo img {
    height: 32px;
    transition: transform 0.25s ease-in-out; }
  body > header .logo:hover img {
    transform: scale(1.1); }
  body > header.landing {
    background: url("../assets/background.png") no-repeat center/cover;
    flex-direction: column;
    height: 100vh;
    justify-content: center; }
    body > header.landing .logo {
      align-items: center;
      align-self: flex-start;
      display: flex;
      height: 6rem; }
    body > header.landing .titles {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      text-align: center; }
      body > header.landing .titles h1,
      body > header.landing .titles h2 {
        font-weight: normal;
        letter-spacing: 0.05rem;
        margin: 0; }
      body > header.landing .titles h1 {
        font-size: 3rem; }
      body > header.landing .titles h2 {
        color: #aaa; }
    body > header.landing .scroll {
      animation: bounce 1s ease-in-out 1s infinite alternate;
      font-size: 2rem;
      opacity: 0.65;
      padding: 2rem 0;
      text-decoration: none; }

@media screen and (orientation: portrait) {
  body > header {
    justify-content: center; }
  body > header.landing .logo {
    align-self: center; } }
